import React, { useEffect, useRef } from "react";
import { spring } from "react-flip-toolkit";
import TechCard from "../components/TechCard";

import "devicon/devicon.min.css";

import slackSvg from "devicon/icons/slack/slack-original.svg";
import discordSvg from "../assets/images/discord.svg";
import sonarLintSvg from "../assets/images/sonarlint.svg";
import sonarQubeSvg from "../assets/images/sonarqube.svg";

import "./styles/homeScreen.css";

const HomeScreen = () => {
  const techSkills = [
    { iconTitle: "HTML 5", className: "devicon-html5-plain colored" },
    { iconTitle: "CSS 3", className: "devicon-css3-plain colored" },
    { iconTitle: "Sass", className: "devicon-sass-original colored" },
    { iconTitle: "Bootstrap", className: "devicon-bootstrap-plain colored" },
    { iconTitle: "Javascript", className: "devicon-javascript-plain colored" },
    { iconTitle: "jQuery", className: "devicon-jquery-plain colored" },
    { iconTitle: "React.js", className: "devicon-react-original colored" },
    { iconTitle: "Next.js", className: "devicon-nextjs-original" },
    {
      iconTitle: "Native",
      className: "devicon-react-original",
      color: "purple",
    },
    { iconTitle: "Node.js", className: "devicon-nodejs-plain colored" },
    { iconTitle: "Express", className: "devicon-express-original" },
    { iconTitle: "Sequelize", className: "devicon-sequelize-plain colored" },
    { iconTitle: "MongoDB", className: "devicon-mongodb-plain colored" },
    { iconTitle: "npm", className: "devicon-npm-original-wordmark colored" },
    { iconTitle: "PHP", className: "devicon-php-plain colored" },
    { iconTitle: "MySql", className: "devicon-mysql-plain colored" },
    { iconTitle: "Symfony", className: "devicon-symfony-original" },
    { iconTitle: "Composer", className: "devicon-composer-line" },
    { iconTitle: "Doctrine", className: "devicon-doctrine-plain colored" },
    { iconTitle: "C Sharp", className: "devicon-csharp-plain colored" },
    { iconTitle: "ASP.NET", className: "devicon-dotnetcore-plain colored" },
    {
      iconTitle: "SqlServer ",
      className: "devicon-microsoftsqlserver-plain colored",
    },
    { iconTitle: "EF Core", className: "devicon-dotnetcore-plain colored" },
    { iconTitle: "WPF/XAML", className: "devicon-dot-net-plain colored" },
    {
      iconTitle: "Visual Studio",
      className: "devicon-visualstudio-plain colored",
    },
    { iconTitle: "VS Code", className: "devicon-vscode-plain colored" },
    { iconTitle: "Slack", svgPath: slackSvg },
    { iconTitle: "Discord", svgPath: discordSvg },
    { iconTitle: "Git", className: "devicon-git-plain colored" },
    { iconTitle: "GitHub", className: "devicon-github-original" },
    { iconTitle: "Sonar Lint", svgPath: sonarLintSvg },
    { iconTitle: "Sonar Qube", svgPath: sonarQubeSvg },
    { iconTitle: "Jira", className: "devicon-jira-plain colored" },
    { iconTitle: "Trello", className: "devicon-trello-plain colored" },
  ];

  console.log(techSkills.length);

  const techSkillsElts = techSkills.map((tech, i) => {
    return (
      <div className="col-4 col-md-3 col-lg-2 mb-2 mb-lg-5" key={i}>
        <TechCard
          className={tech.className}
          iconTitle={tech.iconTitle}
          color={tech.color}
          svgPath={tech.svgPath}
        />
      </div>
    );
  });

  const containerRef = useRef(null);
  useEffect(() => {
    const squares = [...containerRef.current.querySelectorAll(".techCard")];
    squares.forEach((el, i) => {
      spring({
        config: "wobbly",
        values: {
          translateY: [-15, 0],
          opacity: [0, 1],
        },
        onUpdate: ({ translateY, opacity }) => {
          el.style.opacity = opacity;
          el.style.transform = `translateY(${translateY}px)`;
          el.className = el.className.replace("d-none", "d-block");
        },
        delay: i * 200 + 750,
      });
    });
  }, []);

  return (
    <main className="container g-0">
      {/* <TypeWriter /> */}
      <h1 className="text-center mb-5 border">Compétences Techniques</h1>
      <div className="techCardContainer row pb-3" ref={containerRef}>
        {techSkillsElts}
        
      </div>
    </main>
  );
};

export default HomeScreen;
