import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import LeftNavBar from "./components/LeftNavBar";
import TypeWriter from "./components/TypeWriter";
import Content from "./components/Content";



function App() {

  

  return (
    
      <div className="app-container">
        <div className="container-fluid opack">
          <LeftNavBar title="Laurent BEDU" />
          <TypeWriter />
          <Content/>
          
        </div>

        
        <footer className="d-sm-none">
          
        </footer>
      </div>
  );
}

export default App;
