import "./styles/leftNavBar.css";
import { Link } from "react-router-dom";

const LeftNavBar = ({ title }) => {
  
  const navIconClick = (e) => {
    e.currentTarget.classList.toggle("open");
    e.currentTarget.closest(".sidenav").classList.toggle("open");
  };

  const closeSideNav = (e) => {
    e.currentTarget.closest(".sidenav").classList.toggle("open");
    e.currentTarget.closest(".sidenav").querySelector(".nav-icon").classList.toggle("open");
  }

  return (
    <>
      <div className="header">
        <h1 className="header-title">{title}</h1>
      </div>
      <div className="sidenav">
        <h1 className="header-title">{title}</h1>
        <div className="nav-icon" onClick={navIconClick}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="links-container">
          <ul>
            <li><Link to="/" onClick={closeSideNav}>Technologies</Link></li>
            <li><Link to="/skills" onClick={closeSideNav}>Compétences</Link></li>
            <li><Link to="/timeline" onClick={closeSideNav}>Parcours</Link></li>
            <li><Link to="/contact" onClick={closeSideNav}>Contact</Link></li>
            {/* <li>
              <a
                href="./CV_Bedu_Laurent_2022.pdf"
                target="_blank" download
              >
                CV - pdf
              </a>
            </li> */}
            <li><Link to="/404" onClick={closeSideNav}>Erreur 404</Link></li>
          </ul>
        </div>
      </div>
      <div className="spacer"></div>
    </>
  );
};

export default LeftNavBar;
