import SkillCard from '../components/SkillCard';
import './styles/skillsScreen.css';
import React, { useEffect, useRef } from "react";
import { spring } from "react-flip-toolkit";

function SkillsScreen(){

    const containerRef = useRef(null);
    useEffect(() => {
      const squares = [...containerRef.current.querySelectorAll(".skill-card")];
      squares.forEach((el, i) => {
        spring({
          config: "wobbly",
          values: {
            translateY: [-15, 0],
            opacity: [0, 1],
          },
          onUpdate: ({ translateY, opacity }) => {
            el.style.opacity = opacity;
            el.style.transform = `translateY(${translateY}px)`;
            el.className = el.className.replace("d-none", "d-block");
          },
          delay: i * 1500 + 750,
        });
      });
    }, []);

    return(<main className="container g-0" ref={containerRef}>
    <h1 className="text-center mb-3 mb-sm-5 border">Compétences Professionnelles</h1>
        <div className='row pb-3'>
        <SkillCard text="Concevoir, développer, tester et déployer des applications multicouches réparties (web, mobile, desktop)" />
        <SkillCard text="Concevoir et mettre en place des bases de données (sql, noSql)" />
        <SkillCard text="Développer les parties backend et frontend d’une application web" />
        <SkillCard text="Développer des composants métier et des composants d'accès aux données (Api Rest, microservices)" />
        <SkillCard text="Planifier et suivre l’avancement d’un projet depuis la conception jusqu’à la livraison" />
        <SkillCard text="Coordonner, encadrer et faire monter en compétences une équipe de développeurs" />
        <SkillCard text="Élaborer les progressions, scénarios, activités et ressources pédagogiques d’une action de formation" />
        <SkillCard text="Animer des temps de formation collectifs ou individuels, en évaluer les acquis et remédier aux difficultés individuelles d’apprentissage" />
        </div>
    </main>);
}

export default SkillsScreen;