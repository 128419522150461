import Clock from "react-clock";
import "./styles/comingSoon.css";
import React, { useEffect, useState } from "react";

const ComingSoon = ({ title }) => {
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (<main className="container g-0">
  <h1 className="text-center mb-5 border">{title}</h1>
    <div className="px-5 text-center">
      
      <div className="d-flex justify-content-center">
        <Clock value={value} size={280}/>
      </div>
      <h2 className="mt-5"><b>Cette page sera bientôt disponible ...</b></h2>
    </div>
   
  </main>);
};

export default ComingSoon;
