import { useState, useEffect } from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
import ComingSoon from "../screens/ComingSoon";
import HomeScreen from "../screens/HomeScreen";
import SkillsScreen from "../screens/SkillsScreen";
import NotFound from "../screens/NotFound";


function Content() {
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    console.log(displayLocation, location)
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

          {/* <Route path="/test" element={<TechScreen/>} /> */}

  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
      <Routes location={displayLocation}>
        
        <Route path="/" element={<HomeScreen/>} />
        <Route path="/skills" element={<SkillsScreen/>} />
        <Route path="/timeline" element={<ComingSoon title={"Parcours Professionnel"}/>} />
        <Route path="/contact" element={<ComingSoon title={"Contactez-moi ..."}/>} />
        <Route path="*" element={<NotFound />} />
        {/* <Route path="*" element={<HomeScreen/>} /> */}
      </Routes>
    </div>
  );
}

export default Content;
