import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './styles/notFound.css';

function NotFound() {

    const [y, setY] = useState(0);
    const navigate = useNavigate();

    useEffect(()=>{
        setY(document.querySelector('.notfound-container').getBoundingClientRect().y + 20);

        const handleKeyDown = (evt) => {
            document.removeEventListener('keydown', handleKeyDown);
            navigate("/", { replace: true });
        }
        document.addEventListener('keydown', handleKeyDown);

    },[])

    return ( 
    <div className='notfound-container container-fluid' style={{top:-y}}>
        <div className='row'>
        <div className="notfound col-12">
            <div className="centered"><span class="inverted">&nbsp;E&nbsp;R&nbsp;R&nbsp;O&nbsp;R&nbsp;</span></div>
            <div className="centered"><span class="inverted">&nbsp;4&nbsp;0&nbsp;4&nbsp;</span></div>
            <div className="centered"><span class="inverted">&nbsp;N&nbsp;O&nbsp;T&nbsp;&nbsp;F&nbsp;O&nbsp;U&nbsp;N&nbsp;D&nbsp;</span></div>
            <div className="line">&nbsp;</div>
            <div className="line">&nbsp;</div>
            <div className="line">A fatal exception 404 has occurred at C0DE:ABAD1DEA in 0xC0DEBA5E.</div>
            <div className="line">The current request will be terminated.</div>
            <div className="line">&nbsp;</div>
            <div className="line">* Press any key to return to the previous page.</div>
            <div className="line">* Press CTRL+ALT+DEL to restart your computer. You will</div>
            <div className="line">&nbsp;&nbsp;lose any unsaved information in all applications.</div>
            <div className="line">&nbsp;</div>
            <div className="centered">Press any key to continue...<span class="blink">&#9608;</span></div>
        </div>
</div>
    </div> );
}

export default NotFound;