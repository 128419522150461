import './styles/skillCard.css'


function SkillCard({text}){

    return(<div className='skill-card d-none px-3 pb-2 pb-sm-3 pb-lg-5 col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0'>
        <span className='border'>
            <svg className="animated-check" viewBox="0 0 24 24">
                <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> 
            </svg>
        </span>
        <h3 className='d-inline ps-2'>{text}</h3>
    </div>);
}

export default SkillCard;