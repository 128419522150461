import ReactTypingEffect from "react-typing-effect";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import "./styles/typeWriter.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const TypeWriter = () => {
  const location = useLocation();
  const routes = ["/", "/skills", "/timeline", "/contact"];
  const index = routes.indexOf(location.pathname);
  const [pathIndex, setPathIndex] = useState(index);

  useEffect(() => {
    const newIndex = routes.indexOf(location.pathname);
    setPathIndex(newIndex);
  }, [location, routes]);

  return (
    <div className="type-writer-wrapper">
      <div className={(window.innerWidth < 576 ? "bottom left" : "top left")}>
        {pathIndex >= 0 && pathIndex !== 0 && (
          <Link to={routes[pathIndex - 1]}>
            <FiArrowLeft
              size={window.innerWidth < 576 ? "1.6rem" : "2.4rem"}
              color="#212431"
              className="border border-0 rounded-5 bg-arrow"
            />
          </Link>
        )}
      </div>
      <h1 className="type-writer-container pt-3 container">
        <ReactTypingEffect
          text={[
            `Études et Développement${
              window.innerWidth < 420 ? "|" : " "
            }Informatique`,
            "15 années d'expérience",
            "Chef de Projets",
            "Concepteur d'Applications",
            "Tech Lead Développeur",
            "Développeur Full Stack",
            "Formateur Professionnel",
          ]}
          // cursorClassName="text-info ps-1"
          cursorClassName="d-none"
          typingDelay={1000}
          speed={100}
          eraseSpeed={25}
          eraseDelay={1000}
          displayTextRenderer={(text, i) => {
            return (
              <div>
                {text.split("").map((char, i) => {
                  const key = `${i}`;
                  if (char === "|")
                    return <span key={key} className="d-block"></span>;
                  return <b key={key}>{char}</b>;
                })}
                <span className="ps-1 caret"></span>
              </div>
            );
          }}
        />
      </h1>
      <div className={(window.innerWidth < 576 ? "bottom right" : "top right")}>
        {pathIndex >= 0 && pathIndex !== routes.length - 1 && (
          <Link to={routes[pathIndex + 1]}>
            <FiArrowRight
              size={window.innerWidth < 576 ? "1.6rem" : "2.4rem"}
              color="#212431"
              className="border border-0 rounded-5 bg-arrow"
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default TypeWriter;
