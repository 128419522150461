import "./styles/techCard.css";


const TechCard = ({className, iconTitle, color, svgPath}) => {


    return (<>
        <div className="techCard d-none">
            <h2 className="iconTitle">
                {iconTitle}
            </h2>
            <div className="devIcon" >
                {/* <img src={"./assets/images/dev_icons/"+ svgFileName +".svg"} alt={iconTitle}/>           */}
                {className && <i className={className} style={{color:color}}></i>}
                {svgPath && <img src={svgPath} style={{width:'72px', marginTop:'-24px'}} alt={iconTitle}/>}
            </div>
        </div>
    </>);
}

export default TechCard;